<template>
    <el-dialog
    v-model="dialogVisible"
    title="重置密码"
    width="40%">
    <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    :rules="rules"
    label-width="100px"
    size="large">
        <el-form-item label="重置密码" prop="password">
            <el-input v-model="ruleForm.password" type="password" autocomplete="off" show-password />
        </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button  color="#803a15" dark  @click="formSubmit('ruleFormRef')">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { resetPassword } from '@/api/user.js'
import { isvalidPwd } from '@/utils/validate.js'
import { encrypt } from '@/utils/rsaEncrypt'
export default {
    data(){
        const validPwd = (rule, value, callback) => {
            if (value) {
                if(isvalidPwd(value)){
                    callback()
                }else{
                    callback(new Error('密码包含字母、数字、特殊字符且长度至少八位'))
                }
            } else {
                callback()
            }
        }
        return{
            dialogVisible:false,
            ruleForm:{
                id:'',
                password:'',
            },
            rules:{
                password: [
                    { required: true, message: '密码不能为空', trigger: 'blur'},
                    { validator: validPwd, trigger: 'blur' }
                ],
            }
        }
    },
    methods:{
        open(id){
            this.dialogVisible = true
            this.ruleForm = {
                id:id,
                password:''
            }
        },
        formSubmit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.resetPwd(this.ruleForm)
                } else {
                    return false;
                }
            });
        },
        resetPwd(datas){
            let data = JSON.parse(JSON.stringify(datas))
            data.password = encrypt(data.password)
            resetPassword(data).then(r=>{
                this.loading = false
                if(r.code==200){
                    this.$message({
                        type: 'success',
                        message: '密码重置成功!'
                    });
                    this.dialogVisible = false
                }else{
                    this.$message({
                        type: 'warning',
                        message: '密码重置失败!'
                    });
                }
            }).catch(()=>{
                this.loading = false
            })
        },
    }
}
</script>